<template>
  <footer v-if="general" class="pb-8">
    <Container
      class="flex flex-col justify-between gap-14 pb-8 pt-16 md:flex-row md:gap-[10%] 2xl:gap-[20%]"
    >
      <div class="flex flex-col md:flex-row">
        <CableCarIcon class="w-10 -translate-y-7 self-start" />

        <ul
          v-if="general.locations && general.locations.length > 0"
          class="flex flex-col space-y-6 sm:flex-row sm:space-x-12 sm:space-y-0 md:flex-col md:space-x-0 md:space-y-8 md:pl-12"
        >
          <li
            v-for="(location, index) in general.locations"
            :key="index"
            class="flex flex-col items-start"
          >
            <div
              v-if="location.address"
              class="address"
              v-html="location.address"
            />
            <a
              v-if="location.phone"
              :href="`tel:${location.phone}`"
              class="transition-colors hover:text-blue-800"
            >
              {{ location.phone }}
            </a>
            <a
              v-if="location.email"
              :href="`mailto:${location.email}`"
              class="border-b border-transparent text-blue-800 transition hover:border-blue-800"
              >{{ location.email }}</a
            >
          </li>
        </ul>
      </div>

      <nav
        class="flex flex-wrap gap-6 md:flex-col lg:gap-x-12 xl:flex-1 xl:flex-row xl:justify-center"
        :aria-label="$t('footer')"
      >
        <ul
          v-for="({ page, children }, index) in footerLinks"
          :key="index"
          class="min-w-44 xs:w-[calc(50%-0.75rem)] md:w-full xl:w-[calc(33.3%-3rem)]"
        >
          <li>
            <span
              class="mb-1.5 inline-block hyphens-auto text-md font-medium lg:mb-0.5"
            >
              {{ page.title }}
            </span>
          </li>
          <li v-for="(subpage, subpageIndex) in children" :key="subpageIndex">
            <NuxtLink
              :to="subpage.page.url"
              class="mt-2 inline-block hyphens-auto transition-colors duration-150 hover:text-blue-800 lg:mt-2"
            >
              {{ subpage.page.title }}
            </NuxtLink>
          </li>
        </ul>
      </nav>
    </Container>
    <Container
      class="mt-10 flex flex-col gap-6 lg:flex-row lg:items-end lg:justify-between xl:mt-20 xl:items-center xl:gap-8"
    >
      <nav
        :aria-label="`${$t('footer')} meta`"
        class="flex flex-1 flex-col gap-4 2xl:flex-row 2xl:gap-8"
      >
        <!-- META LINKS -->
        <ul class="flex flex-wrap gap-x-8 gap-y-3">
          <li
            v-for="(link, index) in general?.links"
            :key="index"
            class="w-fit"
          >
            <NuxtLink
              :to="link.url"
              class="transition-colors duration-150 hover:text-blue-800"
              >{{ link.title }}
            </NuxtLink>
          </li>
        </ul>

        <div class="flex flex-wrap items-center gap-5">
          <!-- LANG SWITCHER -->

          <ClientOnly>
            <ul
              v-if="alternates.length > 1 || route.path.includes('auth')"
              class="flex"
            >
              <li v-for="locale in locales" :key="locale.code">
                <button
                  class="mr-3 uppercase text-blue-800"
                  :class="{ 'opacity-60': locale.code !== currentLocale }"
                  @click="switchLanguage(locale.code)"
                >
                  {{ locale.code }}
                </button>
              </li>
            </ul>
          </ClientOnly>

          <!-- SOCIAL LINKS -->
          <ul class="flex gap-x-5">
            <li
              v-for="(social, index) in general.social_media_channels"
              :key="index"
            >
              <NuxtLink
                v-if="social.link"
                :to="social.link"
                target="_blank"
                :class="social.link"
                :aria-label="`${$t(
                  'accessibility.link_to_social_media_page'
                )}: ${social.channel.label}`"
                class="inline-block text-blue-800 hover:text-blue-dark-800"
              >
                <Component :is="social.channel.value + '-icon'" class="h-5" />
              </NuxtLink>
            </li>
          </ul>
        </div>
      </nav>

      <!-- QUALITY LABEL & PARTNERS -->
      <div
        class="mb-6 flex gap-6 max-xl:flex-col lg:mb-0 xl:items-center xl:gap-8"
      >
        <div class="flex items-center gap-x-8 lg:gap-x-12">
          <div class="inline-block">{{ $t('quality_label') }}</div>
          <NuxtLink to="https://www.employerswetrust.ch" target="_blank">
            <AppImage
              :src="general.quality_label?.permalink"
              :alt="
                currentLocale === 'fr'
                  ? general.quality_label?.alt_fr
                  : general.quality_label?.alt
              "
              class="w-40 object-contain"
              loading="lazy"
            />
          </NuxtLink>
        </div>
        <div
          class="flex items-center gap-8 lg:flex-row lg:items-center lg:gap-x-12"
        >
          <div class="inline-block">{{ $t('our_partners') }}</div>
          <div v-for="(partner, index) in general.partners" :key="index">
            <NuxtLink
              :to="partner.link"
              target="_blank"
              :aria-label="`${$t('accessibility.link_to')} partner`"
            >
              <AppImage
                :alt="
                  currentLocale === 'fr'
                    ? partner.partner_image?.alt_fr
                    : partner.partner_image?.alt
                "
                :src="partner.partner_image"
                :class="index === 1 ? 'w-40' : 'w-32 mr-12 lg:mr-0'"
                class="object-contain"
                loading="lazy"
              />
            </NuxtLink>
          </div>
        </div>
      </div>
    </Container>
  </footer>
</template>

<script setup>
const store = useGlobalsStore()

const route = useRoute()
const { locale: currentLocale, locales } = useI18n()
const alternates = useState('alternates', () => [])
const switchLocalePath = useSwitchLocalePath()

// COMPUTED
const general = computed(() => store.general)
const footerLinks = computed(() => store.footerLinks)

// FUNCTIONS
function switchLanguage(locale) {
  const isAuthPage = route.path.startsWith('/auth')

  if (
    Array.isArray(alternates.value) &&
    alternates.value.length &&
    !isAuthPage
  ) {
    navigateTo(alternates.value.find((a) => a.locale === locale).url)
  } else {
    navigateTo(switchLocalePath(locale))
  }
}
</script>

<style lang="scss">
footer {
  .address p:first-of-type {
    @apply mb-1 text-md;
  }

  .router-link-exact-active {
    @apply text-blue-800;
  }
  box-shadow: 0px 4px 20px 0px rgba(3, 69, 100, 0.1);
}
</style>
